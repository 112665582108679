.media-item-button-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right:0;
    padding: 10px;
    background-color: white;
    opacity: 0.4;
    transition: all 0.5s;
}

.media-item-button-container:hover {
    opacity: 1;
}


.media-item-video-button-container {
    position: absolute;
    top: 0;
    left: 0;
    right:0;
    padding: 10px;
    background-color: white;
    opacity: 0.4;
    transition: all 0.5s;
}

.media-item-video-button-container:hover {
    opacity: 1;
}